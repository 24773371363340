<template>
    <div class="dash">
        <div class="title">
            <div>
                <img src="@/assets/logo.png" alt="">
                <span>上海体育博物馆数字大屏</span>
            </div>
            <div>更新时间：{{systime}}</div>
        </div>
        <div class="row">
            <el-row :gutter="20">
                <el-col :span="4" :md="4" :sm="12" :xs="24">
                    <div class="shadow ic4">
                        <div>
                            <div>实时在馆人数</div>
                            <div><span>{{today.nowCount}}</span></div>
                        </div>
                        <div><img src="@/assets/dash/1.png" alt=""></div>
                    </div>
                </el-col>
                <el-col :span="4" :md="4" :sm="12" :xs="24">
                    <div class="shadow ic4">
                        <div>
                            <div>小程序累计用户数</div>
                            <div><span>{{today.totalUser}}</span></div>
                        </div>
                        <div><img src="@/assets/dash/1.png" alt=""></div>
                    </div>
                </el-col>
                <el-col :span="4" :md="4" :sm="12" :xs="24">
                    <div class="shadow ic4">
                        <div>
                            <div>今日核销/预约人数</div>
                            <div><span>{{today.checkUser}}/{{today.resUser}}</span></div>
                        </div>
                        <div><img src="@/assets/dash/3.png" alt=""></div>
                    </div>
                </el-col>
                <el-col :span="4" :md="4" :sm="12" :xs="24">
                    <div class="shadow ic4">
                        <div>
                            <div>累计接待团队人数</div>
                            <div><span>{{group.checkNums}}</span></div>
                        </div>
                        <div><img src="@/assets/dash/1.png" alt=""></div>
                    </div>
                </el-col>
                <el-col :span="8" :md="8" :sm="24" :xs="24">
                    <div class="shadow ic8">
                        <div>
                            <div>累计团队预约情况</div>
                            <div><span>{{group.nums}}</span> 个团，共 <span>{{group.peoples}}</span> 人</div>
                        </div>
                        <div class="gr"><img src="@/assets/dash/5.png" alt=""></div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div>
            <el-row :gutter="20">
                <el-col :span="12" :md="12" :sm="24" :xs="24">
                    <div class="shadow">
                        <bar-chart :id="'bar'" :chart-data="barList" />
                    </div>
                </el-col>
                <el-col :span="12" :md="12" :sm="24" :xs="24">
                    <div class="shadow">
                        <line-chart :id="'line'" :chart-data="lineList" />
                    </div>
                </el-col>
            </el-row>
        </div>
        <div>
            <el-row :gutter="20">
                <el-col :span="5" :md="5" :sm="24" :xs="24">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <div class="shadow ic24" style="margin-bottom:32px">
                                <div><img src="@/assets/dash/canguan.png" alt=""></div>
                                <div>
                                    <div>累计预约人数</div>
                                    <div><span>{{total.resNums}}</span></div>
                                </div>
                                
                            </div>
                        </el-col>
                        <el-col :span="24">
                            <div class="shadow ic24">
                                <div><img src="@/assets/dash/yuyue.png" alt=""></div>
                                <div>
                                    <div>累计参观人数</div>
                                    <div><span>{{total.checkNums}}</span></div>
                                </div>
                                
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :span="7" :md="7" :sm="24" :xs="24">
                    <div class="shadow ic7">
                        <div class="title">预约参观人群性别分布</div>
                        <div class="sex">
                            <div class="w">
                                <img src="@/assets/dash/women.png" alt="">
                                <div>女性人数</div>
                                <div class="font">{{total.gender['2'].value}}</div>
                            </div>
                            <div class="t">
                                <div>共计</div>
                                <div class="font">{{total.gender['2'].value+total.gender['1'].value}}</div>
                            </div>
                            <div class="m">
                                <img src="@/assets/dash/man.png" alt="">
                                <div>男性人数</div>
                                <div class="font">{{total.gender['1'].value}}</div>
                            </div>
                            
                        </div>
                    </div>
                </el-col>
                <el-col :span="12" :md="12" :sm="24" :xs="24">
                    <div class="shadow ic12">
                        <div class="title">预约参观人群年龄段分布</div>
                        <div class="percentBox">
                            <template v-for="(item, index) in total.detail">
                                <div v-if="item.percent != 0" :key="index" :style="{ width: item.percent+'%', background: item.color, margin: '0 1px' }" class="percentBox-item" ></div>
                            </template>
                        </div>
                        <div class="tuli">
                            <div class="tuli-item" v-for="(item, index) in total.detail" :key="index">
                                <div class="tuli-item-left">
                                    <div class="tag" :style="{ background: item.color}"></div>
                                    <div class="text1">{{ item.name }}</div>
                                </div>
                                <div class="tuli-item-right">
                                    <div class="text1"><span>{{ item.value }}</span>人</div>
                                    <div class="text3">{{ item.percent }}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import api from '@/api/api'
import moment from 'moment'
import BarChart from './components/BarChart'
import LineChart from './components/LineChart'
export default {
    components: {
        BarChart,
        LineChart
    },
    data () {
        return {
            systime: '',
            group: {},
            doors: [],
            today: {},
            total: {},
            barList: {},
            lineList: {}
        }
    },
    mounted () {
        this.getDashboard()
    },
    methods: {
        getDashboard() {
            api.getDashboard().then(res=>{
                if(res.data.success){
                    this.systime = moment(res.data.sys_time*1000).format('YYYY-MM-DD HH:mm:ss')
                    this.doors = res.data.data.doors
                    this.group = res.data.data.group
                    this.today = res.data.data.today
                    this.total = res.data.data.total
                    let personal = res.data.data.total.month.personal
                    let perData = personal.checkNums.map(row => row.month)
                    let checkData = personal.checkNums.map(row => row.count*1)
                    let resData = personal.resNums.map(row => row.count*1)
                    console.log(perData,checkData,resData)
                    this.barList = {
                        timeData:perData,
                        checkData:checkData,
                        resData:resData
                    }
                    let group = res.data.data.total.month.group
                    let perLData = group.checkNums.map(row => row.month)
                    let checkLData = group.checkNums.map(row => row.count*1)
                    let resLData = group.resNums.map(row => row.count*1)
                    this.lineList = {
                        timeData:perLData,
                        checkData:checkLData,
                        resData:resLData
                    }
                }
            })
        }
        
    }
}
</script>
<style lang="less" scoped>
    .dash{
        background: #2C2C2C;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 24px;
        .title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            img{
                width: 47px;
                height: 47px;
                border-radius: 50%;
            }
            >div{
                &:first-child{
                    font-size: 30px;
                    color: #FFFFFF;
                    letter-spacing: 1.06px;
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                }
                &:nth-child(2){
                    font-size: 21px;
                    color: #FFFFFF;
                    letter-spacing: 0;
                }
            }
        }
        .row{
            margin-top: 20px;
        }
        .shadow{
            background: #2C2C2C;
            border: 0 solid #3B3B3B;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,0.85);
            display: flex;
            width: 100%;
            height: 100%;
            margin-bottom: 20px;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 13px 16px 16px 23px;
        }
        .ic4{
            img{
                width: 48px;
                height: 48px;
            }
            div{
                &:first-child{
                    div{
                        &:first-child{
                            font-size: 14px;
                            color: #FF8B00;
                            letter-spacing: 0.5px;
                        }
                        &:nth-child(2){
                            font-size: 14px;
                            color: rgba(255,255,255,0.85);
                            letter-spacing: 0.5px;
                            span{
                                font-family:'DIN';
                                font-size: 38px;
                                color: #FFFFFF;
                                letter-spacing: 0;
                                text-align: left;
                            }
                            
                        }
                    }
                }
                &:nth-child(2){
                    align-self: flex-end;
                }
            }
        }
        .ic8{
            img{
                width: 144px;
                height: 51px;
            }
            div{
                &:first-child{
                    div{
                        &:first-child{
                            font-size: 14px;
                            color: #FF8B00;
                            letter-spacing: 0.5px;
                        }
                        &:nth-child(2){
                            font-size: 14px;
                            color: rgba(255,255,255,0.85);
                            letter-spacing: 0.5px;
                            span{
                                font-family:'DIN';
                                font-size: 38px;
                                color: #FFFFFF;
                                letter-spacing: 0;
                                text-align: left;
                            }
                            
                        }
                    }
                }
                &:nth-child(2){
                    align-self: flex-end;
                }
            }
        }
        .ic24{
            img{
                width: 77px;
                height: 77px;
            }
            div{
                &:nth-child(2){
                    div{
                        &:first-child{
                            font-size: 14px;
                            color: #FF8B00;
                            letter-spacing: 0.5px;
                        }
                        &:nth-child(2){
                            font-size: 14px;
                            color: rgba(255,255,255,0.85);
                            letter-spacing: 0.5px;
                            span{
                                font-family:'DIN';
                                font-size: 38px;
                                color: #FFFFFF;
                                letter-spacing: 0;
                                text-align: left;
                            }
                            
                        }
                    }
                }
                &:first-child{
                    align-self: flex-end;
                }
            }
        }
        .ic7{
            flex-direction: column;
            width: 100%;
			.title{
				text-align: left;
				font-family: 'PingFangSC-Regular';
				font-size: 14px;
                color: #FF8B00;
                letter-spacing: 0.5px;
			}
            .sex{
                margin-top: 26px;
                display: flex;
                color: #ffffff;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                letter-spacing: 0.5px;
                .font{
                    font-family: 'DIN';
                    font-size: 26px;
                    color: #FFFFFF;
                    letter-spacing: 0.92px;
                }
                .t{
                    background: url(https://oss.mcloud.moveclub.cn/mpms/20210914/1631607790-61405beeae08c.png);
                    width: 172.4px;
                    height: 182px;
                    background-size: 100%;
                    >div{
                        &:first-child{
                            padding-top: 80px;
                        }
                    }
                }
            }
        }
        .ic12{
            flex-direction: column;
            width: 100%;
			.title{
				text-align: left;
				font-family: 'PingFangSC-Regular';
				font-size: 14px;
                color: #FF8B00;
                letter-spacing: 0.5px;
			}
			.percentBox{
				display: flex;
				justify-content: space-between;
                width: 100%;
				margin-top: 10px;
				margin-bottom: 10px;
				.percentBox-item{
					width: 10%;
					// background: red;
					height: 12.2px;
					&:nth-child(1){
						border-radius: 10px 0 0 10px;
					}
					&:last-child{
						border-radius: 0 10px 10px 0;
					}
				}
			}
			.tuli{
                width: 100%;
                box-sizing: border-box;
                padding: 0 20px;
				.tuli-item{
					display: flex;
					align-items: center;
					justify-content: space-between;
                    padding-bottom: 2px;
                    font-size: 14px;
                    color: #ffffff;
					.tuli-item-left{
						display: flex;
						align-items: center;
                        .tag{
							width: 15px;
							height: 8px;
							// background: #B78B52;
							border-radius: 4.5px;
                            margin-right: 41px;
						}
					}
					.tuli-item-right{
                        width: 40%;
						display: flex;
						align-items: center;
                        justify-content: space-between;
					}
				}
			}
		}
    }
</style>