<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme

const animationDuration = 6000

export default {
  props: {
    chartData: {
      type: Object,
      required: true
    },
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    },
    setOptions({ timeData,checkData,resData } = {}) {
      this.chart.clear();
      this.chart.setOption({
        title: {
          text: '团队预约统计',
          textStyle: {
            color: '#FF8B00'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
            data: ['团队核销', '团队预约'],
            right: '20',
            textStyle: {
              color: 'rgba(255,255,255,0.98)'
            }
        },
        backgroundColor: '#2C2C2C',
        // toolbox: {
        //     feature: {
        //         saveAsImage: {}
        //     }
        // },
        grid: {
          top: 50,
          left: '2%',
          right: '2%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: timeData,
          boundaryGap: false,
          splitLine: {
            lineStyle: {
              color: '#555555'
            }
          },
          axisLabel: {
            color: '#ffffff'
          },
          axisTick: {
            lineStyle: {
              color: '#ffffff'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#ffffff'
            }
          }
        }],
        yAxis: [{
          type: 'value',
          axisLine: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#555555'
            }
          },
          axisLabel: {
            color: '#ffffff'
          },
          axisTick: {
            lineStyle: {
              color: '#ffffff'
            }
          }
        }],
        series: [
          {
            name: '团队核销',
            type: 'line',
            barGap: 0,
            emphasis: {
                focus: 'series'
            },
            itemStyle: {
              color: '#FF9201'
            },
            data: checkData
          },
          {
              name: '团队预约',
              type: 'line',
              emphasis: {
                  focus: 'series'
              },
              itemStyle: {
                color: '#8249FE'
              },
              data: resData
          }
        ]
      })      
    }
  }
}
</script>
